import React from 'react';
import picture from '../pictures/hero_picture.jpg';
import HeroParticles from './Particles/HeroParticles';
import CV from '../CV_NathanBreuil.pdf'
import { LazyLoadImage } from "react-lazy-load-image-component";

const Hero = () => {
    return (

        <div id="about" className=" text-white py-10 p-4">
            <HeroParticles ></HeroParticles>
            <div className="container mx-auto flex flex-col-reverse lg:flex-row items-center justify-between ">

                <div className="w-1/3 px-4 lg:mb-0 p-4">
                    <LazyLoadImage src={picture} alt="Nathan's picture" className="mx-auto rounded-lg shadow-lg sm:w-auto w-full md:1/2 lg:w-80" />
                </div>
                <div className="w-5/6 text-center lg:text-left">
                    <h1 className="text-center text-4xl lg:text-4xl xl:text-6xl font-black text-white">
                        Nathan Breuil

                    </h1>


                    <p className="w-full text-base lg:text-lg text-center xl:text-xl mt-4">
                        I am Nathan Breuil, currently working as a data analyst consultant. I completed a Master’s degree in Computer Science from the National School of Applied Science and Technology in 2023. I am looking for a transverse role in data analytics and/or data science in an English-speaking and multicutural environnement.

                    </p>
                    <p className="w-full text-base lg:text-lg text-center xl:text-xl mt-4">
                        I enjoy building data pipelines and BI dashboards to deliver valuable insights and support decision-making processes. Moreover, I have a strong interest in using data to leverage AI related topics.
                        
                    <p className="w-full text-base lg:text-lg text-center xl:text-xl mt-4">
                        During my free time, I play the piano or practice sports like cycling or volleyball. I also love traveling to meet and learn from other people. Do not hesitate to contact me to learn more <span role="img" aria-label="start">✨</span></p>
                    </p>
                    <a href={CV} download="CV_NathanBreuil">
                        <button className="w-50 mx-auto flex bg-mypalette-darkblue font-semibold text-center px-6 py-2 rounded-full mt-8 hover:bg-mypalette-lightblue hover:text-mypalette-darkblue">
                            Download my CV
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Hero;
