import React from 'react';
import { AiOutlineMail, AiOutlineLinkedin } from "react-icons/ai";

const Footer = () => {
  return (
    <footer className="bg-gradient-to-t from-[#1b2430] p-4">
      <div className="container mx-auto flex flex-col items-center">
        <div className="flex space-x-4 mb-4">
          {/* Github
          <a href="https://github.com/el-natnat/">
            <AiOutlineGithub // Replace with the actual LinkedIn icon image
              alt="Github"
              color='white'
              className="w-8 h-8"
            />
          </a>
        */}
        {/* Email */}
        <a href="mailto:nathanbreuil@gmail.com">
          <AiOutlineMail
            color='white' // Replace with the actual email icon image
            alt="Email"
            className="w-8 h-8"
          />
        </a>

        {/* LinkedIn */}
        <a href="https://www.linkedin.com/in/nathanbreuil/">
          <AiOutlineLinkedin // Replace with the actual LinkedIn icon image
            alt="LinkedIn"
            color='white'
            className="w-8 h-8"
          />
        </a>
      </div>

      <p className='text-white'>Made with <span role="img" aria-label="love"> 💕 </span> by Nathan and ChatGPT sometimes :)</p>
    </div>
    </footer >
  );
};

export default Footer;
