import React from 'react';

const ProjectModal = ({ project, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (

    <div className="fixed inset-1 flex justify-center items-center bg-gray-800 bg-opacity-75 z-40 p-8">
      <div className="bg-blue-950 rounded-lg p-8">
        <h3 >{project.title}</h3>
        <p className="text-white mb-4">{project.detailed}</p>
        <p className="text-white mb-4">Location: {project.location}</p>
        <p className="text-white mb-4">Date: {project.date}</p>
        <p className="text-white mb-4">Description: {project.description}</p>
        <p className="text-white mb-4">Skills: {project.skill}</p>
        <button onClick={onClose} className="bg-blue-500 text-white px-4 py-2 rounded-md">Close</button>
      </div>
    </div>

  );
};

export default ProjectModal;
