import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { GiDiploma } from "react-icons/gi";
import { PiExam } from "react-icons/pi";
import { FaBaby } from "react-icons/fa";
import { TbDeviceDesktopCog ,TbDeviceDesktopAnalytics } from "react-icons/tb";

const Education = () => {
  /*const [showDescriptions, setShowDescriptions] = useState({});

  const toggleDescription = (date) => {
    setShowDescriptions((prevState) => ({
      ...prevState,
      [date]: !prevState[date],
    }));
  };*/

  // Define your timeline items as an array of objects
  const timelineItems = [
    {
      date: "September 2000",
      title: "My birth",
      icon: <FaBaby color="#1f2937"/>,
      description: "",
      id: "1"
    },
    {
      date: "September 2018 - 2020",
      title: "Preparatory class MPSI/PSI (Maths sup/spé)",
      icon: <PiExam color="#1f2937"/>,
      school: "Dupuy-de-Lôme high school",
      description: "Completed two-year undergraduate intensive course in mathematics and physics to prepare competitive exams for the French engineering schools.",
      id: "2018-2020"
    },
    {
      date: "September 2020 - 2023",
      title: "Master's degree in Computer Science",
      icon: <GiDiploma color="#1f2937"/>,
      school: "National School of Applied Science and Technology (ENSSAT) - University of Rennes",
      description: "Gained strong skills in development and project management that have been used throughout technical and associative projects. Involved in the student association as a secretary.",
      id: "2020-2022"
    },
    {
      date: "June 2022 - August 2022",
      title: "DevOps developer",
      company: "Coriolis Composites",
      icon: <TbDeviceDesktopCog color="#1f2937"/>,
      description: "Internship as a DevOps automation developer. Set up a Jenkins pipeline to automate build, test and deployment.",
      id: "june2022"
    },
    {
      date: "September 2022 - January 2023",
      title: "Exchange semester",
      school: "Technological University of Dublin",
      icon: <GiDiploma color="#1f2937"/>,
      description: "Spent one academical semester in Ireland as part of the Erasmus program. Studied advanced software development lessons.",
      id: "june2022"
    },
    {
      date: "February 2023 - August 2023",
      title: "Supply chain data analyst",
      icon: <TbDeviceDesktopAnalytics color="#1f2937"/>,
      company: "Alexion AstraZeneca Rare Disease",
      description: "End-of-study internship in a biopharmaceutical company as part of the Global Operations team in Dublin, Ireland.",
      id: "30th January 2023"
    },
    {
      date: "January 2024 - Now",
      title: "Data analyst consultant",
      icon: <TbDeviceDesktopAnalytics color="#1f2937"/>,
      company: "Astek Technology | Naval Group",
      description: "Permanent position as a data analyst consultant for Astek, currently on mission for Naval Group in Lorient, France.",
      id: "4th January 2024"
    }
  ];

  return (
    <div className="p-4 sm:p-8 md:p-12 lg:p-16 ">
      <h2 id="education" >Education</h2>

      <VerticalTimeline>
        {timelineItems.map(({ date, title, company, school, description, id, icon }) => (
          <VerticalTimelineElement
            key={id}
            className="vertical-timeline-element--work"
            dateClassName='text-black text-start text-white'
            date={date}
            icon={icon}
            iconStyle={{ background: 'rgb(152	193	217)', color: '#fff', marginRight: '10px'}}
            //iconOnClick={() => toggleDescription(id)}
            contentStyle={{
              background: 'rgba(51, 57, 57, 0.2)',
              border:'blue',
              color: '#fff',
              display: 'block',
              
            }}
          >
            <h3>{title}</h3>
            {company && <h4 className="vertical-timeline-element-subtitle"><p>Company: {company}</p></h4>}
            {school && <h4 className="vertical-timeline-element-subtitle"><p>School: {school}</p></h4>}

            <p>{description}</p>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </div>
  );
};

export default Education;
