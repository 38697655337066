import React, { useState } from 'react';
import photo1 from '../pictures/projects/color-run.png';
import photo2 from '../pictures/projects/aee.png';
import photo3 from '../pictures/projects/jenkins.png';
import photo4 from '../pictures/projects/portfolio.png';
import photo5 from '../pictures/projects/alexion.png';
import photo6 from '../pictures/projects/france-map.png';
import { LazyLoadImage } from "react-lazy-load-image-component";


import ProjectModal from './ProjectModal'; // Import the modal component

const ExperienceCard = ({ id, title, imageSrc, description, location, date, skill, tag }) => {

  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <ProjectModal project={{ id, title, description, location, date, skill }} isOpen={isModalOpen} onClose={closeModal} /> {/* Render modal */}
      <div className="max-w-sm rounded overflow-hidden shadow-lg m-4 transform hover:scale-105 transition-transform duration-300 ease-in-out " onClick={openModal} >
        <LazyLoadImage src={imageSrc} alt={title} className="w-full"  />

        <div className="px-6 py-4">
          <h3>{title}</h3>

            <p className="text-white text-base line-clamp-3">{description}</p>


          
        </div>
      </div>
    </div>

  );
};

const Experience = () => {
  const [filter, setFilter] = useState('all');

  const handleFilter = (selectedFilter) => {
    setFilter(selectedFilter);
  };

  const projects = [
    {
      id: 1,
      title: 'Color Run of Lannion 2022',
      date:'Sep 2021 - May 2022',
      location:'Lannion 22300, France',
      imageSrc: photo1,
      description: 'Communication officer of the 5th edition of the Color Run of Lannion. Delivered graphic charter, visuals, contents for social media, website, flyers and posters of the event. In charge of the relationship with sponsors, partners and press. It is a non-competitive race that gathered more than 250 people and raised 1900€ for the charity : Les P\'tits Doudous Briochins',
      skill:'Project management - Communication - Teamwork - Social Media - Wordpress - Canvas',
      tag: 'Associative',
    },
    {
      id: 2,
      title: 'Enssat student association',
      date:'May 2021 - Sep 2022',
      location:'Lannion 22300, France',
      imageSrc: photo2,
      description: 'Secretary of the Enssat student association during the school year 2021/2022. Organized the integration of the new students and executed diverse events throughout the year like festive, sport and online events. I was also in charge of the logistics and sales of the association shop.',
      skill:'Communication - Meeting organisation - Teamwork - High degree of initiative - Project management',
      tag: 'Associative',
    },
    {
      id: 3,
      title: 'DevOps automation',
      date:'Jun 2022 - Aug 2022',
      location:'Queven 56230, France',
      imageSrc: photo3,
      description: 'DevOps developer as part of the development team of a software to control robots executing composite manufacturing. I was in charge of the migration of a CI/CD (continuous integration and continuous delivery) pipeline from Java to an automated Jenkins server. I also set up User Interface tests with the Ranorex framework running on a virtual machine.',
      skill:'Java - VirtualBox - Git - Test automation - Jenkins',
      tag: 'Technical',
    },
    {
      id: 4,
      title: 'Web Portfolio',
      date:'Jun 2022 - Now',
      location:'France',
      imageSrc: photo4,
      description: 'Designed and developped the web portfolio you are currently reading. The ambition is to showcase my education background and my working experiences in a detailed and interactive way. ',
      skill:'HTML/CSS - JS - React - TailwindCSS - Git - Figma - ChatGPT/DALL·E',
      tag: 'Technical',
    },
    {
      id: 5,
      title: 'Data & analytics in supply chain planning',
      date:'Feb 2023 - Aug 2023',
      location:'Dublin, Ireland',
      imageSrc: photo5,
      description: "End-of-study internship in Alexion, the Rare Disease Unit of AstraZeneca as part of Global IT Operations. My mission was to improve Business Intelligence reporting, processes and data quality for supply chain planning. I enhanced data load scripts to improve a Qlik Sense stock-at-risk report by identifying gaps and inaccuracies in master data and lead times. Delivered visuals and insights on drug stock levels using Power BI to prepare the transition from Qlik Sense to Power BI. Captured key user stories into a Jira product backlog to support transition of scenario planning processes into the planning solution and ensure standard solution is fit for purpose.",
      skill:'English - Supply chain planning - OMP - QlikSense - Microsoft Power BI - SQL - Databases - Jira',
      tag: 'Technical',
    },
    {
      id: 6,
      title: 'Data analyst consultant',
      date:'Jan 2024 - Now',
      location:'Lorient 56100, France',
      imageSrc: photo6,
      description: "Data analyst consultant for Astek, currently on mission for Naval Group. As part of a global project, I administrated and developed KPIs in a dashboard to lead a transformation project involving multiple departments. Tracked data inaccuracies and improved data quality by crossing multiple data source.",
      skill:'Communication - QlikSense - Excel - PowerQuery - ETL - Databases',
      tag: 'Technical',
    },
  ];

  const filteredProjects =
    filter === 'all'
      ? projects
      : projects.filter((project) => project.tag.toLowerCase() === filter);

  return (
    <div className="container mx-auto">
      <h2 id="experience">Projects</h2>
      <div className="flex justify-center mb-4">
        <button
          onClick={() => handleFilter('all')}
          className={`mx-2 px-4 py-2 bg-mypalette-lightblue text-mypalette-darkblue rounded ${filter === 'all' ? 'bg-opacity-80' : ''
            }`}
        >
          All
        </button>
        <button
          onClick={() => handleFilter('technical')}
          className={`mx-2 px-4 py-2 bg-mypalette-lightblue text-mypalette-darkblue rounded ${filter === 'technical' ? 'bg-opacity-80' : ''
            }`}
        >
          Technical
        </button>
        <button
          onClick={() => handleFilter('associative')}
          className={`mx-2 px-4 py-2 bg-mypalette-lightblue text-mypalette-darkblue rounded ${filter === 'associative' ? 'bg-opacity-80' : ''
            }`}
        >
          Associative
        </button>
      </div>
      <div className="flex flex-wrap justify-center">
        {filteredProjects.map((project) => (
          <ExperienceCard key={project.id} {...project} />
        ))}
      </div>
    </div>

  );
};

export default Experience;
