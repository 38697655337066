import React, { useState } from 'react';
import logo from '../pictures/nb_logo_white_transparent.png';
import { HashLink as Link } from 'react-router-hash-link';
import CV from '../CV_NathanBreuil.pdf'

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="bg-gradient-to-b from-[#1f2937] px-2">
      <div className="container mx-auto flex justify-between items-center p-4">
        <div className="flex items-center">
          <img src={logo} alt="Logo" className="w-10 mx-4" />
          <a href="/" className="text-2xl font-bold text-white">
            Nathan Breuil
          </a>
        </div>
        <div className="hidden md:flex space-x-5 items-center">
          <Link to="/#about" className="text-white hover:text-mypalette-grey"> {/* Use Link instead of anchor tag */}
            About
          </Link>
          <Link to='/#education' className="text-white hover:text-mypalette-grey"> {/* Use Link instead of anchor tag */}
            Education
          </Link>
          <Link to='/#experience' className="text-white hover:text-mypalette-grey"> {/* Use Link instead of anchor tag */}
            Projects
          </Link>

      
          <a href={CV} download="CV_NathanBreuil" className="bg-mypalette-lightblue px-4 py-1 text-mypalette-darkblue text-center rounded-full  hover:bg-mypalette-darkblue hover:text-white" >
            Download my CV
          </a>



        </div>
        <div className="md:hidden">
          <button className="text-white hover:text-gray-300" onClick={toggleMobileMenu}>
            {/* This SVG code represents a "hamburger" icon for the mobile menu toggle button. */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
        </div>
      </div>
      {/* Mobile menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden space-y-2">
          <Link to="/#about" className="block text-white py-2 p-4 hover:bg-mypalette-lightblue hover:text-mypalette-darkblue"> {/* Use Link instead of anchor tag */}
            About
          </Link>
          <Link to="/#education" className="block text-white py-2 p-4 hover:bg-mypalette-lightblue hover:text-mypalette-darkblue"> {/* Use Link instead of anchor tag */}
            Education
          </Link>
          <Link to="/#experience" className="block text-white py-2 p-4 hover:bg-mypalette-lightblue hover:text-mypalette-darkblue"> {/* Use Link instead of anchor tag */}
            Projects
          </Link>
          <a href={CV} download="CV_NathanBreuil" className="block bg-mypalette-lightblue px-4 py-2 text-mypalette-darkblue text-center rounded-full  hover:bg-mypalette-darkblue hover:text-white">
            Download my CV
          </a>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
