// App.js

import React from 'react';
import MainPage from './components/MainPage';
//import Navbar from './components/NavBar';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
function App() {
  return (<div>

    <Router>
      
      <Routes>
        <Route path="/" element={<MainPage />} />



      </Routes>
    </Router>
  </div>);
}

export default App;

/*function App() {  //SAVE
  return (
    <Router>
      <div className="App">
        <NavBar />
        <div className="bg-gradient-to-b from-transparent via-blue-950 to-transparent">
          <Hero />
          <Education />
          <Experience />
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;*/
