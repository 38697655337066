import React from 'react';
import NavBar from './NavBar';
import Hero from './Hero';
import Education from './Education';
import Experience from './Experience';
import Footer from './Footer';




const MainPage = () => {
    return (
        <div className="Main">
            <NavBar />
            <div className="bg-gradient-to-b from-transparent via-blue-950 to-transparent">
                <Hero />
                <Education />
                <Experience />
            </div>
            <Footer />
        </div>

    );
};

export default MainPage;
